import { Direction, Target, Point, Wall, Robot, Color, WallType, Game, State, Size, Robots, Board, ScoringType } from "./gen/types";

export { newGame, randomTarget, createWalls, createRobots, createRandomBoard };

function createRandomBoard(): Board {
  const boardSize = { w: 16, h: 16 };

  const robots = createRobots(boardSize);

  const walls = createWalls(boardSize);

  const board: Board = {
    boardId: Math.random().toString(16).slice(2),
    boardSize,
    robots,
    walls,
    target: randomTarget(robots, walls),
  };

  return board;
}

function newGame(id: string, uid: string): Game {
  return {
    id,
    state: {
      state: State.GameNotStarted,
      timestamp: -1,
    },
    round: null,
    scores: {},
    config: {
      roundStartDelayMs: 3 * 1000,
      roundFinishDelayMs: 2 * 60 * 1000,
      numberOfPointsToWin: 5,
      revealSolutions: true,
      scoringType: ScoringType.OnePointPerGame
    },
    rematch: null,
    players: { [uid]: uid },
  };
}

function random(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function point(minX: number, maxX: number, minY: number, maxY: number): Point {
  return { x: random(minX, maxX), y: random(minY, maxY) };
}

function sample(arr: any[]) {
  return arr[random(0, arr.length - 1)];
}

function pointToString(p: Point): string {
  return "(" + String(p.x) + "," + String(p.y) + ")";
}

function randomTarget(robots: Robots, walls: Wall[]): Target {
  const robotPositions = [robots.red.point, robots.green.point, robots.blue.point, robots.yellow.point].map(pointToString);
  const wall = sample(walls);
  if (robotPositions.includes(pointToString(wall.point))) {
    return randomTarget(robots, walls);
  } else {
    return { point: wall.point, color: randomColor() };
  }
}

// robots

const colors = [Color.Red, Color.Green, Color.Blue, Color.Yellow];

function randomColor() {
  return sample(colors);
}

function createRobots(size: Size): Robots {
  const robots = colors.map((color) => createRobot(size, color));
  const unique = new Set(robots.map(({ point }) => point.x + "-" + point.y));
  return unique.size == colors.length ? { red: robots[0], green: robots[1], blue: robots[2], yellow: robots[3] } : createRobots(size);
}

function createRobot(size: Size, color: Color): Robot {
  return { point: point(0, size.w - 1, 0, size.h - 1), color, facing: Direction.Right };
}

// walls

function createWalls(size: Size): Wall[] {
  let walls = [
    createWall(2, 6, 0, 0, WallType.Tl),
    createWall(9, 13, 0, 0, WallType.Tr),
    createWall(2, 6, 15, 15, WallType.Bl),
    createWall(9, 13, 15, 15, WallType.Br),
    createWall(0, 0, 2, 6, WallType.Tl),
    createWall(0, 0, 9, 13, WallType.Bl),
    createWall(15, 15, 2, 6, WallType.Tr),
    createWall(15, 15, 9, 13, WallType.Br),
    // createWall(7, 7, 7, 7, WallType.Tl),
    // createWall(8, 8, 7, 7, WallType.Tr),
    // createWall(7, 7, 8, 8, WallType.Bl),
    // createWall(8, 8, 8, 8, WallType.Br)
  ];
  walls = addWalls(4, 1, 7, 1, 7, walls);
  walls = addWalls(4, 8, 14, 1, 7, walls);
  walls = addWalls(4, 1, 7, 8, 14, walls);
  walls = addWalls(4, 8, 14, 8, 14, walls);
  return walls;
  // return addWalls(20, 0, 15, 0, 15, []);
}

function addWalls(numWalls: number, minX: number, maxX: number, minY: number, maxY: number, walls: Wall[]): Wall[] {
  if (numWalls > 0) {
    return addWalls(numWalls - 1, minX, maxX, minY, maxY, addWall(minX, maxX, minY, maxY, walls));
  } else {
    return walls;
  }
}

function addWall(minX: number, maxX: number, minY: number, maxY: number, walls: Wall[]): Wall[] {
  const wall = createWall(minX, maxX, minY, maxY, randomWall());
  return isAdjacentOrSameAsAny(
    wall.point.x,
    wall.point.y,
    walls.map((w) => w.point)
  )
    ? addWall(minX, maxX, minY, maxY, walls)
    : [...walls, wall];
}

function randomWall() {
  return [WallType.Tr, WallType.Tl, WallType.Br, WallType.Bl][random(0, 3)];
}

function createWall(minX: number, maxX: number, minY: number, maxY: number, wall: WallType) {
  return { point: point(minX, maxX, minY, maxY), wall };
}

function isAdjacentOrSameAsAny(x: number, y: number, points: { x: number; y: number }[]) {
  return points.some((num) => isAdjacentOrSame(x, y, num.x, num.y));
}

function isAdjacentOrSame(x: number, y: number, x2: number, y2: number) {
  return (
    (x2 == x && y2 == y) ||
    (x2 == x - 1 && y2 == y) ||
    (x2 == x + 1 && y2 == y) ||
    (x2 == x && y2 == y - 1) ||
    (x2 == x && y2 == y + 1) ||
    (x2 == x - 1 && y2 == y - 1) ||
    (x2 == x + 1 && y2 == y - 1) ||
    (x2 == x - 1 && y2 == y + 1) ||
    (x2 == x + 1 && y2 == y + 1)
  );
}
