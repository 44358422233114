import { Elm } from "../elm/Main";
// import * as firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/database";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

import Ports from "./gen/ports";
import * as db from "./db";
import { Game, Flags, Theme, UserPrefs } from "./gen/types";

import { firebaseConfig } from "./firebase_config";

firebase.initializeApp(firebaseConfig);

export const onLogin = (success: (a0: firebase.User | null) => void) => {
  firebase.auth().onAuthStateChanged((user) => {
    console.log(`onAuthStateChanged`, !!user);

    // const functions = firebase.functions();
    // functions
    //   .httpsCallable("helloWorld")({})
    //   .then(r => console.log(r));

    if (user !== null && !user.isAnonymous) {
      db.updateUser({
        uid: user.uid,
        photoURL: user.photoURL || "",
        displayName: user.displayName || "Anonymous",
      });
    }

    success(user);
  });
};

// ask for some permissions

if ("Notification" in window && Notification.permission !== "granted" && Notification.permission !== "denied") {
  Notification.requestPermission(function (permission) {
    console.log("Notification permissions granted: " + permission);
  });
}

// login(user => console.log("login", user), error => console.log("error", error));

// todo: generate  Flags

function localStorageGet(key: string): string | null {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error(e);
    return null;
  }
}

function localStorageSet(key: string, val: string): void {
  try {
    localStorage.setItem(key, val);
  } catch (e) {
    console.error(e);
  }
}

function parseJSON(string: string | null): any {
  if (!string) return null;
  try {
    return JSON.parse(string);
  } catch (e) {
    console.log(e);
    return null;
  }
}

function getTheme(theme: string): Theme {
  // if (theme == Theme.Dark) {
  //   return theme;
  // } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
  //   return Theme.Dark;
  // } else {
  return Theme.Dark;
}

function getPrefs(): UserPrefs {
  const prefs = parseJSON(localStorageGet(PREFS));
  const theme = getTheme(prefs && prefs.theme);
  return { theme };
}

const PREFS = "prefs";
const SOUNDS = "sounds";

document.addEventListener("DOMContentLoaded", function () {
  const flags: Flags = {
    now: new Date().getTime(),
    prefs: getPrefs(),
    viewport: {
      w: document.documentElement.clientWidth,
      h: document.documentElement.clientHeight,
    },
    sounds: localStorageGet(SOUNDS) !== "false",
    notifications: "Notification" in window && Notification.permission === "granted",
  };

  const app = Elm.Main.init({
    flags,
  });

  function fetchGame(id: string) {
    console.log('FG')
    db.fetchGame(
      id,

        ports.gameFetched

        ,
      ports.gameNotFound
    );
    db.fetchEvents(id, ports.eventsFetched, ports.eventAdded);
  }

  function createGame() {
    db.createGame((game: Game) => {
      console.log('CG')
      ports.gameFetched(game);
      fetchGame(game.id);
    });
  }

  const ports = Ports.init(app, {
    submitMoves: ({ gameId, moves }) => {
      db.submitMoves(gameId, moves);
      // setTimeout(() => db.submitMoves(gameId, moves), 300);
      // setTimeout(() => db.submitMoves(gameId, moves), 400);
      // setTimeout(() => db.submitMoves(gameId, moves), 500);
    },
    createMultiPlayerGame: () => {
      createGame();
    },
    createSinglePlayerPuzzle: () => {
      db.createPuzzle(ports.singlePlayerPuzzleFetched);
    },
    fetchGame: (gameId) => {
      console.log('PFG')
      fetchGame(gameId);
      // we do not sub to events when we create the game...
      // db.fetchGame(gameId, ports.gameFetched, ports.gameNotFound);
      // db.fetchEvents(gameId, ports.eventsFetched, ports.eventAdded);
    },
    fetchPuzzle: (puzzleId) => {
      db.fetchPuzzle(puzzleId, ports.singlePlayerPuzzleFetched);
    },
    startNextRound: (gameId) => {
      db.startNextRound(gameId);
    },
    checkStartNextRound: (gameId) => {
      db.checkStartNextRound(gameId);
    },
    nudge: (gameId) => {
      db.nudge(gameId);
    },
    prefsUpdated: (prefs) => {
      localStorageSet(PREFS, JSON.stringify(prefs));
    },
    roundStartDelayChanged: ({ gameId, ms }) => {
      db.updateRoundStartDelay(gameId, ms);
    },
    scoringTypeChanged: ({ gameId, scoringType }) => {
      db.scoringTypeChanged(gameId, scoringType);
    },
    numRoundsToWinChanged: ({ gameId, num }) => {
      db.updateNumRoundsToWin(gameId, num);
    },
    rematch: (gameId) => {
      db.rematch(gameId);
    },
    roundFinishDelayChanged: ({ gameId, ms }) => {
      db.updateRoundFinishDelay(gameId, ms);
    },
    revealSolutionsChanged: ({ gameId, reveal }) => {
      db.updateRevealSolutions(gameId, reveal);
    },
    addMessage: ({ gameId, message }) => {
      db.addMessage(gameId, message);
    },
    fetchUser: (uid) => {
      db.fetchUser(uid, ports.userFetched);
    },
    fetchActiveGame: () => {
      db.fetchActiveGame(ports.gotActiveGame);
    },
    loginWithNick: (nick) => {
      firebase
        .auth()
        .signInAnonymously()
        .then(({ user }) => {
          if (user && user.uid) {
            db.updateUser({
              uid: user.uid,
              photoURL: "",
              displayName: (nick || "Anonymous").substring(0, 32),
            });
          }
        });
    },
    loginWithGoogle: () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      //provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      firebase.auth().signInWithRedirect(provider);
    },
    copyToClipboard: (string: string) => {
      try {
        navigator.clipboard.writeText(string);
      } catch (e) {
        console.error(e);
      }
    },
    playInvalidMoveSound: () => {
      if (localStorageGet(SOUNDS) === "false") return;
      try {
        new Audio("/audio/illegal_move.mp3").play();
      } catch (e) {
        console.error(e);
      }
    },
    playValidMoveSound: () => {
      if (localStorageGet(SOUNDS) === "false") return;
      try {
        new Audio("/audio/legal_move.mp3").play();
      } catch (e) {
        console.error(e);
      }
    },
    playBestSolutionSoundThisPlayer: () => {
      if (localStorageGet(SOUNDS) === "false") return;
      try {
        new Audio("/audio/best_solution_you.mp3").play();
      } catch (e) {
        console.error(e);
      }
    },
    playBestSolutionSoundOtherPlayer: () => {
      if (localStorageGet(SOUNDS) === "false") return;
      try {
        new Audio("/audio/best_solution_other.mp3").play();
      } catch (e) {
        console.error(e);
      }
    },
    playNotBestSolutionSound: () => {
      if (localStorageGet(SOUNDS) === "false") return;
      try {
        new Audio("/audio/not_best.mp3").play();
      } catch (e) {
        console.error(e);
      }
    },
    updateSoundPref: (enabled: boolean) => {
      localStorageSet(SOUNDS, String(enabled));
    },
    displayNotification: (message: string) => {
      if (!document.hasFocus() && "Notification" in window && Notification.permission === "granted") {
        const notification = new Notification(message);
        notification.onclick = function () {
          window.focus();
        };
      }
    },
    enableNotifications: () => {
      if ("Notification" in window && Notification.permission !== "granted") {
        Notification.requestPermission(function (permission) {
          console.log("Notification permissions granted: " + permission);
        });
      }
    },
  });

  onLogin((user) => {
    if (user) {
      ports.userAuthenticated({
        uid: user.uid,
        displayName: user.displayName || "",
        photoURL: user.photoURL || "",
      });
    } else {
      ports.userNotAuthenticated(null);
    }
  });

  firebase
    .database()
    .ref(".info/serverTimeOffset")
    .once("value", function (snapshot) {
      var offset = snapshot.val();
      // var estimatedServerTimeMs = new Date().getTime() + offset;
      console.log(`offset: `, offset);
      // console.log(`estimatedServerTimeMs`, estimatedServerTimeMs);
      ports.gotServerTimeOffset(offset);
    });

  document.addEventListener("keydown", (event) => {
    if (event.keyCode === 9) {
      event.preventDefault();
      ports.tabPressed(event.keyCode);
    }
  });
});

window.addEventListener("error", (event) => document.write(event.message));
