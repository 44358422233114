export const firebaseConfig = {
  apiKey: "AIzaSyCx4Ea4ZOS8_XaEodY9Eckcom2uKOhObFI",
  authDomain: "galactic-run.firebaseapp.com",
  databaseURL: "https://galactic-run.firebaseio.com",
  projectId: "galactic-run",
  storageBucket: "galactic-run.appspot.com",
  messagingSenderId: "58961474735",
  appId: "1:58961474735:web:34ef295d4feeb93398905a",
  measurementId: "G-3MYWPC8CJR"
};
